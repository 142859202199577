<template>
  <b-container fluid="xl">
    <b-row>
      <NarrowTitle 
      :title="title"
      img="career"
      />
      <b-col cols="12">
        <div class="inner-container">
          <div 
            v-if="jobs===null"
            class="empty-div"></div>
          <NoData v-else-if="jobs.length <= 0">
            <h2>{{tr("carrer-message")}}</h2>
          </NoData>
          <div v-else>
            
            <transition-group name="slide-up" appear>
              <CareerItem 
                v-for="(job, idx) in jobs"
                :key="'job' + idx"
                :job="job"
                :idx="idx"
                />
            </transition-group>
          </div>
        </div>
        <SimpleLine />
        <Contact />
        <FooterBanner />
      </b-col>                            
    </b-row>
  </b-container>
</template>

<script>
import NoData from '@/components/NoData.vue'
import CareerItem from '@/components/CareerItem.vue'
import NarrowTitle from '@/components/NarrowTitle.vue'
import SimpleLine from '@/components/decors/SimpleLine.vue'
import Contact from '@/components/Contact'
import FooterBanner from '@/components/FooterBanner'
import apiH from '@/mixins/apiHandlerMI.js'
export default {
  name: 'Career',
  mixins: [apiH],
  components: {
      CareerItem,
      NoData,
      SimpleLine,
      NarrowTitle,
      Contact,
      FooterBanner
  },
  data() {
    return {
      // title: this.$store.state.menu[5].name,
      jobs: null
      }
  },
  computed:{
    title(){
      let name
      let menuItems = this.$store.state.menu
      for(let i=0; i<menuItems.length; i++) {
        if(menuItems[i].target_link == 'karrier' || menuItems[i].target_link == 'career') {
          name = menuItems[i].name
        }
      }
      return name
    }
  },
  created () {
    var _this = this
    _this.get('job', 
      {
        params: {                        
            relations: 1,
            where: [['where','status', '1']],   
            order_by:[
                ['created_at','desc']                
            ],                    
        }
      },
      function(resp){       
          _this.jobs = resp.data.job
          //_this.jobs = []
      }, function(err){
          console.log(err)
          _this.jobs = []
      }
    ),
    
    this.setMetaTags({
        title: "NT Élelmiszertermelő és Kereskedelmi Kft. - Karrier", 
        desc: "NT Élelmiszertermelő és Kereskedelmi Kft. aktuális állásajánlatai", 
        keywords: 'NT KFt, NT Élelmiszertermelő és Kereskedelmi Kft, napraforgó étolaj, álláshirdetés', 
        ogType: 'website', 
        ogTitle: "NT Élelmiszertermelő és Kereskedelmi Kft. - Karrier",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc:"NT Élelmiszertermelő és Kereskedelmi Kft. aktuális állásajánlatai"
    })
  },  
}
</script>