<template>
    <div class="career-item">
        <div
            class="career-item__header" 
            v-b-toggle="'collapse-' + idx"
            @click="isOpen = !isOpen; setDataLayer(job.name) "
            >
            <div class="career-item__header--date">
                <p v-if="currentLangCode === 'hu'">{{ job.updated_at | moment("YYYY. MMMM Do ") }}</p>
                <p v-if="currentLangCode === 'en'">{{ job.updated_at | moment("Do MMMM YYYY") }}</p>
            </div>
            <div class="career-item__header--title">
                <h2>{{ job.name }}</h2>
            </div>
            
            <div 
                class="career-item__header--hashtags">
                <div 
                    v-for="(tag, idx) in job.job_tag"
                    :key="'tag' + idx"
                    class="career-item__header--hashtags--tag">
                    <p>{{ tag.name }}</p>   
                </div>
            </div>
            
            <div 
                class="career-item__header--button"
                :class="isOpen ? 'career-item__header--button--open' : ''"                
            >
                <p>{{tr("more")}}</p>
            </div>
        </div>
        <b-collapse
            :id="'collapse-' + idx"
            class="career-item__content">
            <div 
                class="career-item__content--txt" 
                v-html="job.description"></div>
            <div class="career-item__content--footer">
                <div class="career-item__content--footer--address bold-text">
                    <p>Munkavégzés helye:</p>
                </div>
                <div class="career-item__content--footer--subaddress">
                    <p>{{ job.job_address }}</p>
                </div>
                <div class="career-item__content--footer--email">
                    <p>
                        <span>Jelentkezését várjuk </span>
                        <span class="bold-text">{{ job.email_subject }}</span>
                        <span> tárggyal az </span>
                        <span class="bold-text">
                            <a :href="'mailto: ' + job.email_address">{{ job.email_address }}</a>
                        </span>
                        <span> e-mail címre.</span>

                    </p>
                </div>                 
            </div>
        </b-collapse>   
    </div>
</template>

<script>
export default {
    props: ['job', 'idx'],
    data() {
        return {
        isOpen: false
        }
    }, 
    methods:{
        setDataLayer(label){
             this.GTtrackGA4({
                'event' : 'event',
                'category': 'careers',
                'action': 'learn_more_click',
                'label': label
            })
        }
    }
    
}
</script>